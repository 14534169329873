export default `documentList {
  ...on documentList_document_BlockType {
    id
    titleText
    description
    document {
      url
      size
      filename
    }
  }
}`
