import helpers from './helpers/helpers'

// Component structures
import seo from './components/seo'
import heroCarousel from './components/heroCarousel'
import missionContainer from '@/api/components/missionContainer'
import clientReviews from '@/api/components/clientReviews'
import simpleServicesList from '@/api/components/simpleServicesList'
import statisticsContainer from '@/api/components/statisticsContainer'
import simpleContentBlock from '@/api/components/simpleContentBlock'
import staffList from '@/api/components/staffList'
import servicesList from '@/api/components/servicesList'
import simpleHero from '@/api/components/simpleHero'
import contentSections from '@/api/components/contentSections'
import documentList from '@/api/components/documentList'
import faqAccordion from '@/api/components/faqAccordion'
import contactForm from '@/api/components/contactForm'
import staffMember from '@/api/components/staffMember'

export default {
  loadHome() {
    const requestData = {
      query: `{
        entry(slug: "home") {
          ... on home_home_Entry {
            ${seo}
            ${heroCarousel}
            ${missionContainer}
            ${clientReviews}
            ${simpleServicesList}
            ${statisticsContainer}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.home')
  },

  loadAboutUs() {
    const requestData = {
      query: `{
        entry(slug: "about-us") {
          ...on aboutUs_aboutUs_Entry {
            ${seo}
            ${simpleContentBlock}
            ${staffList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.about-us')
  },

  loadStaffMember(slug) {
    const requestData = {
      query: `{
        entry(uri: "staff/${slug}") {
          ...on staff_default_Entry {
            ${seo}
            ${staffMember}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, `view.staff-member.${slug}`)
  },

  loadServices() {
    const requestData = {
      query: `{
        entry(slug: "services") {
          ...on ourServices_ourServices_Entry {
            ${seo}
            ${simpleContentBlock}
            ${servicesList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.our-services')
  },

  loadService(slug) {
    const requestData = {
      query: `{
        entry(uri: "services/${slug}") {
          ...on services_default_Entry {
            title
            ${seo}
            ${simpleHero}
            ${contentSections}
            reprintPermission
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, `view.service.${slug}`)
  },

  loadSymptomCondition(slug) {
    const requestData = {
      query: `{
        entry(uri: "symptoms-conditions/${slug}") {
          ...on symptomsConditions_default_Entry {
            title
            ${seo}
            ${simpleHero}
            ${contentSections}
            reprintPermission
          }
        }
      }`,
    }

    return helpers.makeContentRequest(
      requestData,
      `view.symptom-condition.${slug}`
    )
  },

  loadPatientForms() {
    const requestData = {
      query: `{
        entry(slug: "patient-forms") {
          ...on patientForms_patientForms_Entry {
            ${seo}
            ${simpleContentBlock}
            ${documentList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.patient-forms')
  },

  loadFaq() {
    const requestData = {
      query: `{
        entry(uri: "frequently-asked-questions") {
          ... on frequentlyAskedQuestions_frequentlyAskedQuestions_Entry {
            ${seo}
            ${simpleContentBlock}
            ${faqAccordion}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.faq')
  },

  loadContact() {
    const requestData = {
      query: `{
        entry(uri: "contact") {
          ... on contact_contact_Entry {
            ${seo}
            ${simpleContentBlock}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.contact')
  },

  loadEmployment() {
    const requestData = {
      query: `{
        entry(uri: "employment") {
          ... on employment_employment_Entry {
            ${seo}
            ${simpleContentBlock}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'view.employment')
  },

  loadLandingPage(slug) {
    const requestData = {
      query: `{
        entry(uri: "landing-page/${slug}") {
          ...on landingPage_default_Entry {
            title
            ${seo}
            ${simpleHero}
            ${contentSections}
            ${contactForm}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, `view.landing-page.${slug}`)
  },
}
