<template>
  <div
    :class="[{ show: chartsVisible }, 'statistics-container container']"
    v-observe-visibility="{
      callback: statisticsVisibilityChanged,
      once: true,
      intersection: {
        threshold: 0.3,
      },
    }"
  >
    <div
      v-for="statistic in statisticsContainerData"
      :key="statistic.id"
      class="statistic"
    >
      <div class="donut-box">
        <div :id="`chart_${statistic.id}`" class="donut-chart"></div>
        <div class="stats-information">
          <h5>{{ statistic.titleText }}</h5>
          <span class="percentage"> {{ statistic.percentage }}% </span>
        </div>
      </div>

      <p class="description">{{ statistic.description }}</p>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
export default {
  name: 'StatisticsContainer',
  props: {
    statisticsContainerData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        series: [21.8, 78.2], //Default values; to be changed.
        dataLabels: {
          enabled: false,
        },
        colors: ['rgb(241, 120, 0)', 'rgb(35, 31, 32)'],
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          show: true,
          colors: ['#000000'],
        },
        plotOptions: {
          pie: {
            donut: {
              size: '85%',
              background: '#000000',
            },
          },
        },
      },
      chartsVisible: false,
    }
  },
  methods: {
    statisticsVisibilityChanged(isVisible) {
      if (!isVisible) return
      this.mountChart()
      this.chartsVisible = true
    },
    mountChart() {
      this.statisticsContainerData.forEach((statistic) => {
        // Rendering the donut chart for each of our instances.

        const percentage = statistic.percentage
        this.chartOptions.series[0] = percentage
        this.chartOptions.series[1] = 100 - percentage

        const chart = new ApexCharts(
          document.querySelector(`#chart_${statistic.id}`),
          this.chartOptions
        )

        chart.render()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.statistics-container {
  @apply flex flex-wrap justify-evenly opacity-0;
  padding: 40px 0;
  transition: all 0.6s ease-in;

  &.show {
    @apply opacity-100;
  }

  .statistic {
    @apply w-full mb-10;

    .donut-box {
      @apply relative mx-10;
      min-height: 200px;

      .stats-information {
        @apply absolute w-full;
        top: 38%;
        bottom: 50%;
        color: #cfcfcf;

        h5 {
          font-size: 16px;
          font-family: Roboto, sans-serif;
          font-weight: 300;
        }

        span {
          font-size: 32px;
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
          line-height: 32px;
        }
      }
    }

    p.description {
      padding: 16px 15px 0 15px;
      white-space: pre-wrap;
      color: #cfcfcf;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 600px) {
    .statistic {
      @apply w-1/2;

      .donut-box {
        .stats-information {
          top: 30%;

          span {
            font-size: 26px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .statistic {
      @apply w-1/3;

      .donut-box {
        .stats-information {
          top: 30%;

          span {
            font-size: 26px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .statistic {
      .donut-box {
        .stats-information {
          top: 38%;

          span {
            font-size: 32px;
          }
        }
      }
    }
  }
}
</style>
