<template>
  <div :class="[{ open: open }, 'mobile-navigation-container']">
    <nav id="mobileNav" aria-label="mobile navigation">
      <router-link
        v-for="navLink in headerData.navigation"
        :key="navLink.id"
        :to="navLink.anchor"
        :target="navLink.newTab ? '_blank' : '_self'"
      >
        <FontAwesomeIcon :icon="navLink.icon" />
        {{ navLink.anchorTitle }}
      </router-link>
    </nav>

    <SocialNavigation :header-data="headerData" />

    <div class="contact-us">
      <router-link to="/contact">
        <FontAwesomeIcon class="icon" icon="comment-alt" /> Have a Question?
      </router-link>
    </div>
  </div>
</template>

<script>
import SocialNavigation from '@/components/Globals/SocialNavigation'
export default {
  name: 'MobileNavigation',
  components: { SocialNavigation },
  props: {
    headerData: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-navigation-container {
  @apply bg-black py-0;
  border-bottom: 0 groove #a45200;
  max-height: 0;
  transition: all 0.5s linear;

  &.open {
    @apply py-8;
    max-height: 1000px;
    border-bottom-width: 4px;
  }

  nav#mobileNav {
    @apply pb-6;

    a {
      @apply block uppercase font-light;
      padding: 8px 14px;
      font-family: OswaldLight, serif;
      font-size: 16px;
      color: #cfcfcf;
      transition: background-color 0.3s ease-in-out;

      &:hover,
      &.router-link-active {
        background-color: #be5f00;
      }
    }
  }

  .contact-us {
    @apply relative mt-6;
    margin-left: 10px;
    a {
      @apply text-white;
      background-color: #f17800;
      border-radius: 3px;
      text-shadow: -1px -1px 0px rgba(0, 0, 0, 40%);
      font-family: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
      font-size: 13px;
      padding: 8px 14px;
      transition: background-color 0.3s ease-in-out;

      .icon {
        filter: drop-shadow(-1px -1px 0px rgba(0, 0, 0, 40%));
      }

      &:hover {
        background-color: #be5f00;
      }
    }
  }

  @media screen and (min-width: 900px) {
    @apply hidden;
  }
}
</style>
