<template>
  <div class="client-reviews">
    <div class="container">
      <div class="mission-container">
        <h3 v-html="clientReviewData.titleText"></h3>
        <div class="description" v-html="clientReviewData.description"></div>
      </div>

      <div
        :class="[{ show: reviewsVisible }, 'reviews']"
        v-observe-visibility="{
          callback: reviewsVisibilityChanged,
          once: true,
          intersection: {
            threshold: 0.5,
          },
        }"
      >
        <h3>{{ clientReviewData.reviewsTitle }}</h3>

        <div
          class="review"
          v-for="review in clientReviewData.reviews"
          :key="review.id"
        >
          <blockquote>
            <div class="review-text">{{ review.text }}</div>
            <cite class="author-text">{{ review.author }}</cite>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientReviews',
  props: {
    clientReviewData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reviewsVisible: false,
    }
  },
  methods: {
    reviewsVisibilityChanged(isVisible) {
      if (!isVisible) return

      setTimeout(() => {
        this.reviewsVisible = true
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.client-reviews {
  background-color: #231f20;
  margin: 64px 0;

  .container {
    @apply flex items-end flex-col;

    .mission-container,
    .reviews {
      @apply w-full;
      padding: 20px;
    }

    .mission-container {
      @apply text-left mt-10;

      h3 {
        @apply text-white;
        font-family: 'PT Sans Narrow', sans-serif;
        font-size: 28px;
        border-bottom: 1px solid #4f4f4f;
        padding-bottom: 16px;
      }

      .description {
        color: #cfcfcf;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 9px;
      }
    }

    .reviews {
      @apply text-left;
      background: #f17800;
      border: 1px solid #be5f00;

      &.show {
        .review blockquote {
          @apply opacity-100;

          .author-text {
            @apply left-0 opacity-100;
          }
        }
      }

      h3 {
        @apply text-black;
        font-size: 28px;
        font-family: FjallaOneRegular, sans-serif;
      }

      .review {
        &:first-of-type {
          margin-bottom: 20px;

          &:after {
            @apply block w-full;
            content: ' ';
            border-bottom: 1px solid #4f4f4f;
            margin-top: 20px;
          }
        }

        &:last-of-type {
          margin-top: 20px;

          blockquote {
            transition: all 0.7s ease-in 0.8s;

            .author-text {
              transition: all 0.7s linear 1.1s;
            }
          }
        }

        blockquote {
          @apply opacity-0;
          border-left: 1px solid #ffffff;
          transition: all 0.7s ease-in;

          .review-text {
            @apply text-white;
            padding: 9px 40px 0 20px;
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
            font-size: 16px;
            text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
          }

          .author-text {
            @apply text-black relative opacity-0;
            left: 20%;
            padding-left: 20px;
            font-family: FjallaOneRegular, sans-serif;
            font-style: normal;
            font-size: 24px;
            transition: all 0.7s linear 0.3s;

            &:before {
              content: '\2014 \0020';
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .mission-container {
        h3 {
          font-size: 46px;
        }
      }

      .reviews {
        h3 {
          font-size: 46px;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      @apply flex-row;

      .mission-container,
      .reviews {
        @apply w-1/2;
      }

      .mission-container {
        h3 {
          font-size: 36px;
        }

        .description {
          font-size: 17px;
        }
      }

      .reviews {
        h3 {
          font-size: 36px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .mission-container {
        h3 {
          font-size: 46px;
        }

        .description {
          font-size: 18px;
        }
      }

      .reviews {
        h3 {
          font-size: 46px;
        }
      }
    }
  }
}
</style>
