<template>
  <div
    id="loading-screen-container"
    :class="{ loading: pageLoading, transitioning }"
  >
    <img src="@/assets/itpt_man_black-lg.png" alt="orca creative loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoadingScreen',
  data() {
    return {
      transitioning: false,
    }
  },
  watch: {
    pageLoading(newVal) {
      if (newVal) return

      this.transitioning = true
      setTimeout(() => {
        this.transitioning = false
      }, 600)
    },
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
}
</script>

<style lang="scss" scoped>
#loading-screen-container {
  @apply absolute top-0 bottom-0 w-screen flex items-center justify-center
    opacity-0 bg-black max-w-full;
  z-index: -999999999999;

  &.transitioning {
    z-index: 9999;
    transition: opacity 0.3s ease-in;
  }

  &.loading {
    @apply opacity-100;
    z-index: 9999;
  }

  img {
    @apply fixed top-1/2 right-0 bottom-1/2 left-0 mx-auto;
    filter: drop-shadow(4px 4px 4px #cfcfcf);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
    }
  }
}
</style>
