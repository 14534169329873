import helpers from './helpers/helpers'
import servicesList from '@/api/components/servicesList'

export default {
  loadHeader() {
    const requestData = {
      query: `{
        globalSet(handle: "header") {
          ...on header_GlobalSet {
            header {
              ...on header_BlockType {
                address
                addressLink
                telephoneNumber
                facebookLink
                instagramLink
                twitterLink
                youtubeLink
                logo {
                  url
                  title
                }
                navigation {
                  ...on navigation_link_BlockType {
                    id
                    anchor
                    anchorTitle
                    newTab
                    icon
                  }
                }
              }
            }
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.header')
  },

  loadFooter() {
    const requestData = {
      query: `{
        globalSet(handle: "footer") {
          ...on footer_GlobalSet {
            footer {
              ...on footer_BlockType {
                locationIframe
                hoursList {
                  ...on hoursList_hours_BlockType {
                    id
                    days
                    hours
                  }
                }
                titleText
                subtitle
                phoneNumber
                faxNumber
                address
                copyright
                footerLinks {
                  ...on footerLinks_anchorLink_BlockType {
                    id
                    anchorLink
                    titleText
                    newTab
                  }
                }
              }
            }
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.footer')
  },

  loadServicesList() {
    const requestData = {
      query: `{
        entry(slug: "services") {
          ...on ourServices_ourServices_Entry {
            ${servicesList}
          }
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.services-list')
  },

  loadAnatomyCategories() {
    const requestData = {
      query: `{
        categories(group: "anatomyCategories") {
          id
          title
        }
      }`,
    }

    return helpers.makeContentRequest(requestData, 'globals.anatomy-categories')
  },

  loadSymptomsConditionsList() {
    const requestData = {
      query: `{
        entries(section: "symptomsConditions") {
          ... on symptomsConditions_default_Entry {
            id
            title
            uri
            anatomyCategory {
              ...on anatomyCategories_Category {
                id
                title
              }
            }
          }
        }
      }`,
    }

    return helpers.makeContentRequest(
      requestData,
      'globals.symptoms-conditions-list'
    )
  },
}
