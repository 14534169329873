<template>
  <footer>
    <div class="upper-footer">
      <div class="container">
        <div class="location-container">
          <h4>Location</h4>
          <div
            v-if="footerData.locationIframe"
            v-html="footerData.locationIframe"
          ></div>
        </div>

        <div class="details-container">
          <div class="hours-container">
            <h4>Hours</h4>
            <div class="hours-listings" v-if="footerData.hoursList">
              <div
                class="hours-list-item"
                v-for="hoursItem in footerData.hoursList"
                :key="hoursItem.id"
              >
                <div class="days">{{ hoursItem.days }}</div>
                <div class="hours">{{ hoursItem.hours }}</div>
              </div>
            </div>
          </div>

          <div class="location-details">
            <h4>{{ footerData.titleText }}</h4>
            <p>{{ footerData.subtitle }}</p>
            <a class="phone-number" :href="footerData.phoneNumber" title="Call">
              <FontAwesomeIcon icon="phone" flip="horizontal" />
              {{ phoneText }}
            </a>
            <a class="fax-number" :href="faxNumber" title="Fax">
              <FontAwesomeIcon icon="fax" />
              {{ faxText }}
            </a>
            <div class="address">
              {{ footerData.address }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lower-footer">
      <div class="container">
        <div class="copyright" v-html="footerData.copyright"></div>

        <div class="links-container">
          <AnchorLink
            v-for="footerLink in footerData.footerLinks"
            :key="footerLink.id"
            :anchor-link="footerLink.anchorLink"
            :new-tab="footerLink.newTab"
          >
            {{ footerLink.titleText }}
          </AnchorLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
import helpers from '@/api/helpers/helpers'
import api from '@/api'
export default {
  name: 'SiteFooter',
  components: { AnchorLink },
  data() {
    return {
      footerData: {},
    }
  },
  computed: {
    phoneText() {
      if (!this.footerData.phoneNumber) return ''

      const phoneNumber = this.footerData.phoneNumber.replace('tel:+1', '')

      return this.formatPhoneNumber(phoneNumber)
    },
    faxNumber() {
      if (!this.footerData.faxNumber) return ''

      return this.footerData.faxNumber.replace('tel:+1', 'fax:+1')
    },
    faxText() {
      if (!this.footerData.faxNumber) return ''

      const faxNumber = this.footerData.faxNumber.replace('tel:+1', '')

      return this.formatPhoneNumber(faxNumber)
    },
  },
  mounted() {
    this.loadFooter()
  },
  methods: {
    loadFooter() {
      this.setFooterData(helpers.readStoredResponse('globals.footer'))

      api.globals.loadFooter().then((response) => {
        this.setFooterData(response)
      })
    },
    setFooterData(response) {
      if (!response || !response.globalSet) return

      this.footerData = response.globalSet.footer[0] || {}
    },
    formatPhoneNumber(phoneNumber) {
      return (
        '(' +
        phoneNumber.slice(0, 3) +
        ') ' +
        phoneNumber.slice(3, 6) +
        '-' +
        phoneNumber.slice(6)
      )
    },
  },
}
</script>

<style lang="scss">
footer {
  .upper-footer {
    background-color: #151314;
    border-top: 2px groove rgba(113, 57, 0, 0.7);
    border-bottom: 2px groove rgba(113, 57, 0, 0.5);
    padding: 2em 0;

    .container {
      @apply flex flex-wrap;

      .location-container {
        @apply w-full flex flex-col items-center mb-10;
        padding: 0 15px;

        h4 {
          @apply text-white;
          font-family: OswaldLight, serif;
          font-size: 34px;
          margin-bottom: 20px;
        }

        div iframe {
          width: 350px;
          height: 300px;
        }
      }

      .details-container {
        @apply w-full;
        padding: 0 15px;

        .hours-container {
          h4 {
            @apply text-white;
            font-family: OswaldLight, serif;
            font-size: 34px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(88, 44, 0, 0.5);
          }

          .hours-listings {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(88, 44, 0, 0.5);

            .hours-list-item {
              @apply flex;
              padding-top: 20px;

              .days,
              .hours {
                @apply text-right;
                font-family: Roboto, sans-serif;
                font-weight: 300;
                font-size: 20px;
                line-height: 26px;
              }

              .days {
                @apply w-5/12;
                color: #cfcfcf;
              }

              .hours {
                @apply w-7/12;
                padding-right: 0;
                color: #d76b00;
              }
            }
          }
        }

        .location-details {
          h4 {
            @apply text-white;
            font-family: OswaldLight, serif;
            font-size: 30px;
            padding-top: 20px;
          }

          p {
            @apply text-white;
            font-family: Roboto, sans-serif;
            font-size: 18px;
            font-weight: 300;
          }

          .phone-number,
          .fax-number {
            @apply block;
            padding-top: 15px;
            font-family: Roboto, sans-serif;
            font-size: 26px;
            font-weight: 100;
            color: #f17800;
          }

          .address {
            padding-top: 15px;
            color: #cfcfcf;
            font-family: Roboto, sans-serif;
            font-weight: 300;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .lower-footer {
    @apply bg-black px-4;

    .container {
      @apply flex flex-col-reverse flex-wrap justify-center items-center;
      padding: 20px 0;

      .copyright,
      .links-container {
        @apply w-full;
        color: #f17800;
        font-size: 16px;
        font-family: Roboto, sans-serif;
      }

      .copyright {
        @apply text-left mt-4;
        font-weight: 300;
      }

      .links-container {
        @apply text-right;

        a {
          font-weight: 100;
          margin-left: 22px;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .upper-footer {
      .container {
        .location-container {
          div iframe {
            width: 500px;
            height: 450px;
          }
        }

        .details-container {
          .hours-container {
            .hours-listings {
              .hours-list-item {
                .days,
                .hours {
                  font-size: 23px;
                  line-height: 32px;
                }

                .hours {
                  padding-right: 100px;
                }
              }
            }
          }

          .location-details {
            h4 {
              font-size: 34px;
            }
            p {
              font-size: 23px;
            }

            .phone-number,
            .fax-number {
              font-size: 34px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .upper-footer {
      .container {
        .location-container {
          @apply w-1/2;

          div iframe {
            width: 400px;
            height: 350px;
          }
        }

        .details-container {
          @apply w-1/2;
        }
      }
    }

    .lower-footer {
      .container {
        @apply flex-row;

        .copyright,
        .links-container {
          @apply w-1/2;
        }

        .copyright {
          @apply mt-0;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .upper-footer {
      .container {
        .location-container {
          div iframe {
            width: 500px;
            height: 450px;
          }
        }
      }
    }
  }
}
</style>
