export default `id
bioContainer {
  ...on bioContainer_BlockType {
    titleText
    bio
    portfolioImage {
      url
      title
    }
  }
}`
