<template>
  <SiteHeader />
  <div id="page">
    <LoadingScreen />
    <router-view :key="$route.path" />
  </div>
  <SiteFooter />
</template>

<script>
import SiteHeader from './components/Globals/SiteHeader'
import SiteFooter from '@/components/Globals/SiteFooter'
import LoadingScreen from '@/components/UI/LoadingScreen'
export default {
  components: { LoadingScreen, SiteFooter, SiteHeader },
}
</script>

<style lang="scss">
@import 'sass/app';

#app {
  @apply bg-cover bg-fixed bg-center min-h-screen text-center antialiased overflow-x-hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  background-image: url('~@/assets/body_bg.jpg');
  max-width: 100vw;
}

#page {
  @apply relative;
  min-height: 80vh;
}
</style>
