import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutUs.vue'),
  },
  {
    path: '/staff/:slug',
    name: 'StaffMember',
    component: () =>
      import(/* webpackChunkName: "staffMember" */ '../views/StaffMember.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () =>
      import(/* webpackChunkName: "ourServices" */ '../views/OurServices.vue'),
  },
  {
    path: '/services/:slug',
    name: 'Service',
    component: () =>
      import(/* webpackChunkName: "service" */ '../views/Service.vue'),
  },
  {
    path: '/symptoms-conditions/:slug',
    name: 'SymptomCondition',
    component: () =>
      import(
        /* webpackChunkName: "symptomCondition" */ '../views/SymptomCondition.vue'
      ),
  },
  {
    path: '/patient-forms',
    name: 'PatientForms',
    component: () =>
      import(
        /* webpackChunkName: "patientForms" */ '../views/PatientForms.vue'
      ),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/employment',
    name: 'Employment',
    component: () =>
      import(/* webpackChunkName: "employment" */ '../views/Employment.vue'),
  },
  {
    path: '/landing-page/:slug',
    name: 'landingPage',
    component: () =>
      import(/* webpackChunkName: "landingPage" */ '../views/LandingPage.vue'),
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'
      ),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'PageNotFound' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    store.commit('setPageLoadingTrue')
  }

  next()
})

export default router
