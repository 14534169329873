<template>
  <div class="hero-carousel">
    <Carousel
      class="carousel-wrapper"
      :autoplay="8000"
      :items-to-show="0.9"
      :wrap-around="true"
    >
      <Slide
        class="carousel-slide"
        v-for="carouselItem in heroCarouselData"
        :key="carouselItem.id"
      >
        <img :src="carouselItem.backgroundImage[0].url" alt="" />
        <div class="content-container">
          <div class="container">
            <strong>{{ carouselItem.titleText }}</strong>
            <p>{{ carouselItem.subtitle }}</p>
            <AnchorLink
              v-if="carouselItem.anchorLink"
              class="anchor-link"
              :anchor-link="carouselItem.anchorLink"
            >
              {{ carouselItem.anchorText }}&nbsp;
              <FontAwesomeIcon icon="angle-double-right" />
            </AnchorLink>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation class="carousel-navigation">
          <template #next>
            <FontAwesomeIcon icon="caret-right" />
          </template>
          <template #prev>
            <FontAwesomeIcon icon="caret-left" />
          </template>
        </Navigation>
      </template>
    </Carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'HeroCarousel',
  components: {
    AnchorLink,
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    heroCarouselData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      itemsLoaded: [],
    }
  },
  beforeMount() {
    this.preloadImages()
  },
  methods: {
    preloadImages() {
      this.heroCarouselData.forEach((carouselItem) => {
        const image = document.createElement('img')

        image.onload = () => {
          this.itemsLoaded.push(true)

          if (this.itemsLoaded.length >= this.heroCarouselData.length) {
            this.$emit('loaded')
          }
        }

        image.src = carouselItem.backgroundImage[0].url
      })
    },
  },
}
</script>

<style lang="scss">
.hero-carousel {
  @apply overflow-hidden;

  .carousel-wrapper {
    .carousel-slide {
      img {
        aspect-ratio: 15 / 7;
      }

      .content-container {
        @apply absolute w-full bottom-0 text-white flex items-center;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        max-width: 100vw;

        .container {
          @apply px-12;
          padding-top: 18px;

          strong {
            font-family: FjallaOneRegular, sans-serif;
            font-size: 22px;
            line-height: 1em;
            font-weight: 400;
          }

          p {
            font-family: Roboto, serif;
            font-size: 16px;
            line-height: 1.2em;
            font-weight: 300;
            color: #cfcfcf;
          }

          .anchor-link {
            @apply float-right flex items-center;
            color: #f17800;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 300;
          }
        }
      }
    }

    .carousel-navigation {
      @apply bg-transparent;
      font-size: 60px;
      width: 60px;
      height: 60px;
      border-radius: 0;
      transition: background-color 0.3s ease-in-out;

      &.carousel__prev {
        left: 30px;
      }
      &.carousel__next {
        right: 30px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  @media screen and (min-width: 600px) {
    .carousel-wrapper {
      .carousel-slide {
        .content-container {
          top: 50%;

          .container {
            strong {
              font-size: 30px;
            }

            p,
            .anchor-link {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .carousel-wrapper {
      .carousel-slide {
        .content-container {
          top: 60%;

          .container {
            strong {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .carousel-wrapper {
      .carousel-slide {
        .content-container {
          top: 66%;
        }
      }
    }
  }
}
</style>
