export default `simpleServicesList {
  ...on simpleServicesList_BlockType {
    titleText
    services {
      ...on services_default_Entry {
        id
        uri
        listTile {
          ...on listTile_BlockType {
            titleText
            shortDescription
          }
        }
      }
    }
  }
}`
