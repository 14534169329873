<template>
  <div class="simple-services-list container">
    <h4>{{ simpleServicesListData.titleText }}</h4>

    <div
      :class="[{ show: servicesVisible }, 'services-list']"
      v-observe-visibility="{
        callback: servicesVisibilityChanged,
        once: true,
        intersection: {
          threshold: 0.3,
        },
      }"
    >
      <div
        class="service"
        v-for="service in simpleServicesListData.services"
        :key="service.id"
      >
        <router-link :to="'/' + service.uri">
          <h5>{{ service.listTile[0].titleText }}</h5>

          <div class="content">
            <div class="icon-container">
              <img
                src="@/assets/itpt_man_black-lg.png"
                :alt="service.listTile[0].titleText"
              />
            </div>
            <div class="content-container">
              {{ service.listTile[0].shortDescription }}
              <AnchorLink class="anchor-link" :anchor-link="'/' + service.uri">
                Learn More <FontAwesomeIcon icon="angle-double-right" />
              </AnchorLink>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'SimpleServicesList',
  components: { AnchorLink },
  props: {
    simpleServicesListData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesVisible: false,
    }
  },
  methods: {
    servicesVisibilityChanged(isVisible) {
      if (!isVisible) return

      setTimeout(() => {
        this.servicesVisible = true
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.simple-services-list.container {
  @apply overflow-visible;

  h4 {
    color: #d76b00;
    font-size: 34px;
    font-family: OswaldLight, serif;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }

  .services-list {
    @apply flex flex-wrap;
    padding-top: 20px;

    &.show {
      .service {
        @apply opacity-100 left-0 top-0;
      }
    }

    .service {
      @apply w-full relative opacity-0 left-5 top-5;
      padding-bottom: 35px;
      transition: all 0.5s ease-in;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}n) {
          transition: all 0.5s ease-in #{$i * 0.2}s;
        }
      }

      a {
        &:hover {
          h5,
          .content {
            transform: scale(1.1);
          }
        }

        h5 {
          @apply text-left w-full;
          color: #f17800;
          font-family: Roboto, sans-serif;
          font-size: 23px;
          font-weight: 300;
          margin-bottom: 8px;
          transition: all 0.5s ease-in;
        }

        .content {
          @apply flex;
          transition: all 0.5s ease-in;

          .icon-container {
            padding-right: 15px;
            img {
              max-width: 65px;
            }
          }

          .content-container {
            @apply text-left;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 300;
            color: #cfcfcf;
            padding-right: 24px;

            .anchor-link {
              @apply block;

              color: #f17800;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .services-list {
      .service {
        @apply w-1/2;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .services-list {
      .service {
        @apply w-1/3;

        a {
          h5 {
            font-size: 20px;
          }

          .content {
            .icon-container {
              img {
                max-width: 50px;
              }
            }

            .content-container {
              padding-right: 12px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .services-list {
      .service {
        a {
          h5 {
            font-size: 23px;
          }

          .content {
            .icon-container {
              img {
                max-width: 65px;
              }
            }

            .content-container {
              padding-right: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
