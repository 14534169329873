export default `contentSections {
  ... on contentSections_grayContentBlock_BlockType {
    id
    typeHandle
    sectionTitle
    sectionContent
  }
  ... on contentSections_contentblock_BlockType {
    id
    typeHandle
    sectionTitle
    sectionContent
  }
}`
