export default `clientReviews {
  ...on clientReviews_BlockType {
    titleText
    description
    reviewsTitle
    reviews {
      ...on reviews_review_BlockType {
        id
        text
        author
      }
    }
  }
}`
