export default `heroCarousel {
  ...on heroCarousel_carouselItem_BlockType {
    id
    backgroundImage {
      url
    }
    titleText
    subtitle
    anchorLink
    anchorText
  }
}`
