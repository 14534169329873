<template>
  <div class="homeView">
    <HeroCarousel
      v-if="heroCarouselData.length"
      :hero-carousel-data="heroCarouselData"
      @loaded="homeLoaded"
    />
    <template v-if="homeViewLoaded">
      <MissionContainer
        v-if="missionContainerData"
        :mission-container-data="missionContainerData"
      />
      <ClientReviews
        v-if="clientReviewData"
        :client-review-data="clientReviewData"
      />
      <SimpleServicesList
        v-if="simpleServicesListData"
        :simple-services-list-data="simpleServicesListData"
      />
      <StatisticsContainer
        v-if="statisticsContainerData.length"
        :statistics-container-data="statisticsContainerData"
      />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import HeroCarousel from '@/components/Carousel/HeroCarousel'
import MissionContainer from '@/components/UI/MissionContainer'
import ClientReviews from '@/components/UI/ClientReviews'
import SimpleServicesList from '@/components/Services/SimpleServicesList'
import StatisticsContainer from '@/components/UI/StatisticsContainer'
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    StatisticsContainer,
    SimpleServicesList,
    ClientReviews,
    MissionContainer,
    HeroCarousel,
  },
  data() {
    return {
      homeViewLoaded: false,
      heroCarouselData: [],
      missionContainerData: null,
      clientReviewData: null,
      simpleServicesListData: null,
      statisticsContainerData: [],
    }
  },
  mounted() {
    this.loadHome()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadHome() {
      this.setHomeData(helpers.readStoredResponse('view.home'))

      api.views.loadHome().then((response) => {
        this.setHomeData(response)
      })
    },
    setHomeData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.heroCarouselData = entry.heroCarousel || []
      this.missionContainerData = entry.missionContainer
        ? entry.missionContainer[0]
        : null
      this.clientReviewData = entry.clientReviews
        ? entry.clientReviews[0]
        : null
      this.simpleServicesListData = entry.simpleServicesList
        ? entry.simpleServicesList[0]
        : null
      this.statisticsContainerData = entry.statisticsContainer || []
    },
    homeLoaded() {
      setTimeout(() => {
        this.setPageLoadingFalse()
        this.homeViewLoaded = true
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped></style>
