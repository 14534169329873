require('./bootstrapped')

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Plugins
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarkerAlt,
  faPhone,
  faCommentAlt,
  faHome,
  faInfoCircle,
  faClipboardList,
  faCopy,
  faComments,
  faAngleDoubleRight,
  faCaretRight,
  faCaretLeft,
  faFax,
  faDownload,
  faFileWord,
  faFileAlt,
  faFilePdf,
  faSpinner,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faMapMarkerAlt,
  faPhone,
  faCommentAlt,
  faHome,
  faInfoCircle,
  faClipboardList,
  faCopy,
  faComments,
  faAngleDoubleRight,
  faCaretRight,
  faCaretLeft,
  faFax,
  faDownload,
  faFileWord,
  faFileAlt,
  faFilePdf,
  faSpinner,
  faCheckCircle
)

// Allow craftCMS previews.
let m = document.location.href.match(/\btoken=([^&]+)/)
const previewToken = m ? m[1] : ''
const requestPath = previewToken === '' ? '/api' : '/api?token=' + previewToken

const app = createApp(App)

axios.create({
  baseURL: requestPath,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`,
  },
})

app.config.globalProperties.$axios = axios

import { ObserveVisibility } from 'vue-observe-visibility'
app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
})

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .mixin({
    methods: {
      updatePageTitle(newTitle) {
        document.title = newTitle
      },
    },
  })
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .mount('#app')
