<template>
  <ul class="social-media">
    <li v-if="headerData.facebookLink">
      <a :href="headerData.facebookLink" rel="noreferrer" target="_blank">
        <FontAwesomeIcon :icon="['fab', 'facebook-f']" />
      </a>
    </li>

    <li v-if="headerData.instagramLink">
      <a :href="headerData.instagramLink" rel="noreferrer" target="_blank">
        <FontAwesomeIcon :icon="['fab', 'instagram']" />
      </a>
    </li>

    <li v-if="headerData.youtubeLink">
      <a :href="headerData.youtubeLink" rel="noreferrer" target="_blank">
        <FontAwesomeIcon :icon="['fab', 'youtube']" />
      </a>
    </li>

    <li v-if="headerData.twitterLink">
      <a :href="headerData.twitterLink" rel="noreferrer" target="_blank">
        <FontAwesomeIcon :icon="['fab', 'twitter']" />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SocialNavigation',
  props: {
    headerData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
ul.social-media {
  li {
    @apply inline-block;

    a {
      @apply flex items-center justify-center rounded-full;
      font-size: 20px;
      color: #2c3e50;
      background-color: #f17800;
      width: 32px;
      height: 32px;
      margin-left: 6px;
      transition: all 0.3s ease-in;

      &:hover {
        color: rgba(44, 62, 80, 0.5);
      }
    }
  }
}
</style>
