<template>
  <div class="mission-container" ref="missionContainer">
    <div
      :class="[{ show: headingVisible }, 'container']"
      v-observe-visibility="{
        callback: headingVisibilityChanged,
        once: true,
        intersection: {
          threshold: 0.2,
        },
      }"
    >
      <h2>
        {{ missionContainerData.titleText }}
      </h2>
      <div class="subtitle" v-html="missionContainerData.description"></div>

      <div
        :class="[{ show: missionsVisible }, 'mission-statements']"
        v-observe-visibility="{
          callback: missionsVisibilityChanged,
          once: true,
          intersection: {
            threshold: 0.3,
          },
        }"
      >
        <div
          v-for="objective in missionContainerData.missionStatements"
          :key="objective.id"
          class="objective"
        >
          <div class="heading">
            <h3>{{ objective.titleText }}</h3>
          </div>
          <div class="statement" v-html="objective.description"></div>
          <AnchorLink class="anchor-link" :anchor-link="objective.anchorLink">
            {{ objective.anchorText }}&nbsp;
            <FontAwesomeIcon icon="angle-double-right" />
          </AnchorLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'MissionContainer',
  components: { AnchorLink },
  props: {
    missionContainerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headingVisible: false,
      missionsVisible: false,
    }
  },
  methods: {
    headingVisibilityChanged(isVisible) {
      if (!isVisible) return

      setTimeout(() => {
        this.headingVisible = true
      }, 500)
    },
    missionsVisibilityChanged(isVisible) {
      if (!isVisible) return

      setTimeout(() => {
        this.missionsVisible = true
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.mission-container {
  @apply bg-black text-white;
  padding-top: 43px;

  .container {
    &.show {
      h2 {
        @apply opacity-100;
      }
      div.subtitle {
        @apply opacity-100;
      }
    }

    h2 {
      @apply uppercase opacity-0;
      font-family: OswaldLight, serif;
      font-size: 30px;
      line-height: 50px;
      margin-bottom: 27px;
      transition: all 0.6s ease-in;
    }

    div.subtitle {
      @apply opacity-0;
      font-size: 20px;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      color: #cfcfcf;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      transition: all 0.6s ease-in 0.4s;
    }

    .mission-statements {
      @apply flex flex-wrap;
      padding-bottom: 245px;

      &.show {
        .objective {
          @apply opacity-100 left-0;
        }
      }

      .objective {
        @apply w-full opacity-0 relative;
        left: 100vw;
        transition: all 1s linear;

        &:nth-of-type(2) {
          transition: all 1s linear 0.3s;
        }
        &:nth-of-type(3) {
          transition: all 1s linear 0.6s;
        }

        .heading {
          @apply py-6;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);

          h3 {
            font-family: OswaldLight, serif;
            color: #d76b00;
            font-size: 34px;
          }
        }

        .statement {
          @apply text-left;
          font-family: Roboto, sans-serif;
          font-size: 20px;
          font-weight: 300;
          color: #cfcfcf;
          margin-top: 20px;
          padding-right: 6px;
        }

        .anchor-link {
          @apply float-right flex items-center;
          color: #f17800;
          font-family: Roboto, serif;
          font-weight: 300;
          font-size: 18px;
          margin-top: 14px;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: #d76b00;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h2 {
        font-size: 35px;
        line-height: 55px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      h2 {
        font-size: 45px;
        line-height: 65px;
      }

      div.subtitle {
        font-size: 21px;
      }

      .mission-statements {
        @apply flex-nowrap;

        .objective {
          @apply w-1/3;

          &:nth-of-type(2) {
            @apply mx-6;
          }

          .statement {
            font-size: 21px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      h2 {
        font-size: 55px;
        line-height: 77px;
      }

      div.subtitle {
        font-size: 23px;
      }

      .mission-statements {
        .objective {
          .statement {
            font-size: 23px;
          }
        }
      }
    }
  }
}
</style>
