export default `staffList {
  ...on staffList_BlockType {
    selectedStaff {
      ...on staff_default_Entry {
        id
        bioContainer {
          ...on bioContainer_BlockType {
            titleText
            bio
            portfolioImage {
              url
              title
            }
          }
        }
      }
    }
  }
}`
