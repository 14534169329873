<template>
  <router-link v-if="isRouterLink" :to="anchorLink">
    <slot></slot>
  </router-link>
  <a
    v-else
    :href="anchorLink"
    :target="newTab ? '_blank' : '_self'"
    rel="noreferrer"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'AnchorLink',
  props: {
    anchorLink: {
      type: String,
      required: true,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRouterLink() {
      return (
        !this.anchorLink.includes('http://') &&
        !this.anchorLink.includes('https://') &&
        this.newTab === false
      )
    },
  },
}
</script>

<style scoped></style>
