export default `servicesList {
  ...on servicesList_BlockType {
    selectedServices {
      ...on services_default_Entry {
        id
        title
        uri
        listTile {
          ...on listTile_BlockType {
            titleText
            shortDescription
            backgroundImage {
              url
              title
            }
          }
        }
      }
    }
  }
}`
