export default `missionContainer {
  ...on missionContainer_BlockType {
    titleText
    description
    missionStatements {
      ...on missionStatements_mission_BlockType {
        id
        titleText
        description
        anchorLink
        anchorText
      }
    }
  }
}`
