<template>
  <header>
    <div class="contact-information container">
      <div class="address">
        <a :href="headerData.addressLink" rel="noreferrer" target="_blank">
          <FontAwesomeIcon class="icon" icon="map-marker-alt" />
          {{ headerData.address }}
        </a>
      </div>

      <div class="phone-number">
        <a :href="headerData.telephoneNumber" rel="noreferrer">
          <FontAwesomeIcon class="icon" icon="phone" flip="horizontal" />
          {{ phoneText }}
        </a>
      </div>

      <SocialNavigation class="social-navigation" :header-data="headerData" />

      <div class="contact-us">
        <router-link to="/contact">
          <FontAwesomeIcon class="icon" icon="comment-alt" /> Have a Question?
        </router-link>
      </div>
    </div>

    <div class="header-bar">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <img
              v-if="logoData.url"
              :src="logoData.url"
              :alt="logoData.title"
            />
            <img
              v-else
              src="@/assets/riverside-logo.png"
              alt="Riverside Physical Therapy"
            />
          </router-link>
        </div>
        <nav id="nav" aria-label="primary navigation">
          <router-link
            v-for="navLink in headerData.navigation"
            :key="navLink.id"
            :to="navLink.anchor"
            :target="navLink.newTab ? '_blank' : '_self'"
          >
            <FontAwesomeIcon :icon="navLink.icon" />
            {{ navLink.anchorTitle }}
          </router-link>
        </nav>

        <MobileHamburger
          class="hamburger-button"
          @opened="openMobileNav = true"
          @closed="openMobileNav = false"
        />
      </div>
    </div>

    <MobileNavigation :header-data="headerData" :open="openMobileNav" />
  </header>
</template>

<script>
import api from '@/api'
import helpers from '@/api/helpers/helpers'
import MobileNavigation from '@/components/Globals/MobileNavigation'
import SocialNavigation from '@/components/Globals/SocialNavigation'
import MobileHamburger from '@/components/Globals/MobileHamburger'
export default {
  name: 'SiteHeader',
  components: { MobileHamburger, SocialNavigation, MobileNavigation },
  data() {
    return {
      headerData: {},
      logoData: {},
      openMobileNav: false,
    }
  },
  computed: {
    phoneText() {
      if (!this.headerData.telephoneNumber) return ''

      let phoneNumber = this.headerData.telephoneNumber.replace('tel:+1', '')
      return (
        phoneNumber.slice(0, 3) +
        '.' +
        phoneNumber.slice(3, 6) +
        '.' +
        phoneNumber.slice(6)
      )
    },
  },
  watch: {
    $route() {
      if (!this.openMobileNav) return
      this.openMobileNav = false
    },
  },
  mounted() {
    this.loadHeader()
  },
  methods: {
    loadHeader() {
      this.setHeaderData(helpers.readStoredResponse('globals.header'))

      api.globals.loadHeader().then((response) => {
        this.setHeaderData(response)
      })
    },
    setHeaderData(response) {
      if (!response || !response.globalSet) return

      this.headerData = response.globalSet.header[0] || {}
      this.logoData = this.headerData.logo ? this.headerData.logo[0] ?? {} : {}
    },
  },
}
</script>

<style lang="scss">
header {
  z-index: 9999999;

  .contact-information {
    @apply flex justify-end items-end flex-wrap w-11/12;
    padding: 4px 0;
    margin-right: 1em !important;

    .address,
    .phone-number {
      @apply font-normal;
      font-family: 'PT Sans Narrow', sans-serif;
      line-height: 25.6px;
      padding: 6px 0 4px 0;

      a {
        color: #f17800;
        font-size: 16px;

        .icon {
          color: #cfcfcf;
          font-size: 19px;
          margin-right: 6px;
        }
      }
    }

    .phone-number {
      margin-left: 20px;
    }

    .social-media {
      @apply hidden;
    }

    .contact-us {
      @apply relative hidden;
      margin-left: 10px;
      a {
        @apply text-white;
        background-color: #f17800;
        border-radius: 3px;
        text-shadow: -1px -1px 0px rgba(0, 0, 0, 40%);
        font-family: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
        font-size: 13px;
        padding: 8px 14px;
        transition: background-color 0.3s ease-in-out;

        .icon {
          filter: drop-shadow(-1px -1px 0px rgba(0, 0, 0, 40%));
        }

        &:hover {
          background-color: #be5f00;
        }
      }
    }
  }

  .header-bar {
    @apply bg-black;
    border-top: 4px ridge #8b4500;
    border-bottom: 4px groove #a45200;

    .container {
      @apply flex items-center justify-between overflow-visible;
      height: 65px;

      .logo {
        @apply relative w-2/5;

        a {
          img {
            @apply absolute;
            z-index: 9999999;
            transform: translateY(-50%);
            max-height: 147px;
          }
        }
      }

      nav#nav {
        @apply hidden;

        a {
          @apply inline-block uppercase font-light;
          padding: 20px 14px;
          font-family: OswaldLight, serif;
          font-size: 16px;
          color: #cfcfcf;
          transition: background-color 0.3s ease-in-out;

          &:hover,
          &.router-link-active {
            background-color: #be5f00;
          }
        }
      }

      .hamburger-button {
        @apply relative right-0;
      }
    }
  }

  @media screen and (min-width: 550px) {
    .contact-information {
      @apply w-3/5;
    }
  }

  @media screen and (min-width: 900px) {
    .contact-information {
      @apply flex-nowrap w-full;
      margin-right: auto !important;

      .social-media {
        @apply inline-block;
      }

      .contact-us {
        @apply flex;
      }
    }

    .header-bar {
      .container {
        @apply justify-start;

        .logo {
          @apply w-1/4;
        }

        nav#nav {
          @apply block;
        }

        .hamburger-button {
          @apply hidden;
        }
      }
    }
  }
}
</style>
